const layout = (state = {}, action) => {
    switch (action.type) {
        case "GET_INDICES":
            console.log("Get inde")
            return {
                ...state,
                fetchIndices: true,
            }
        case "GET_INDICES_SUCCESS":
            return {
                ...state,
                fetchIndices: false,
                indices: action.indices
            }
        default:
            return state;
    }
}

export default layout;