import React from "react";

const Tournaments =()=>{
    return(
        <div>
            <h2>Tournaments is loading. Wait to win more.</h2>
        </div>
    )
}

export default Tournaments;