import { buyStock, searchStockData, sellStock, stockHistoryData, get, getIndices, getRecommendations } from "../api/stocks";
import { loadUserConfig } from "./user";

const GET_STOCK = 'GET_STOCK';
const GET_STOCK_HISTORY = 'GET_STOCK_HISTORY';
const GET_STOCK_DETAILS_SUCCESS = 'GET_STOCK_DETAILS_SUCCESS';
const GET_STOCK_HISTORY_DETAILS_SUCCESS = 'GET_STOCK_HISTORY_DETAILS_SUCCESS';
const BUY_STOCK_SUCCESS = 'BUY_STOCK_SUCCESS';
const SELL_STOCK_SUCCESS = 'SELL_STOCK_SUCCESS';
const GET_STOCK_DETAILS_FAILED = 'GET_STOCK_DETAILS_FAILED';
const GET_STOCK_HISTORY_DETAILS_FAILED = 'GET_STOCK_HISTORY_DETAILS_FAILED';
const BUY_STOCK_FAILED = 'BUY_STOCK_FAILED';
const SELL_STOCK_FAILED = 'SELL_STOCK_FAILED';
const BUY_STOCK = 'BUY_STOCK';
const SELL_STOCK = 'SELL_STOCK';
const UPDATE_BS_ACTION = 'UPDATE_BS_ACTION';
const DEFAULT_STOCK = 'DEFAULT_STOCK';
const BUY_SELL_MESSAGE_UPDATE = 'BUY_SELL_MESSAGE_UPDATE';
const GET_STOCK_RECOMMENDATIONS = "GET_STOCK_RECOMMENDATIONS";
const GET_STOCK_RECOMMENDATIONS_SUCCESS = "GET_STOCK_RECOMMENDATIONS_SUCCESS";

const getStock = (stock) => {
    return {
        type: GET_STOCK,
        isStockFetching: true,
    }
};

export const defaultStockUpdate = (stock) => {
    return {
        type: DEFAULT_STOCK,
        stock
    }
}

const getStockData = (details) => {
    return {
        type: GET_STOCK_DETAILS_SUCCESS,
        details
    }
};

const getStockDataFailed = (action) => {
    return {
        type: GET_STOCK_DETAILS_FAILED,
        action
    }
};

const buyStockStatus = (action) => {
    return {
        type: BUY_STOCK,
        action,
        isBSAction: action,
        //buySellMessage: "Your order is in progress. Stay calm!!!"
    }
};

export const updateBSStatus = (action) => {
    return {
        type: UPDATE_BS_ACTION,
        action
    }
};

const buyStockSuccess = (action) => {
    return {
        type: BUY_STOCK_SUCCESS,
        action,
        buySellMessage: "Buy stock success!!! Nice pick"
    }
};

const buyStockFailed = (action) => {
    return {
        type: BUY_STOCK_FAILED,
        action,
        buySellMessage: "Buy stock Failed!!! Check Your Account Balance"
    }
};

const sellStockSuccess = (action) => {
    return {
        type: SELL_STOCK_SUCCESS,
        action,
        buySellMessage: "Sell stock success!!!"
    }
};

const sellStockFailed = (action) => {
    return {
        type: SELL_STOCK_FAILED,
        action,
        buySellMessage: "Sell stock failed!!!"
    }
};


const buySellMessageUpdate = (action) => {
    return {
        type: BUY_SELL_MESSAGE_UPDATE,
        buySellMessage: action
    }
};

const getStockHistoryData = (details) => {
    return {
        type: GET_STOCK_HISTORY_DETAILS_SUCCESS,
        details
    }
};

const getStockHistoryDataFailed = (action) => {
    return {
        type: GET_STOCK_HISTORY_DETAILS_FAILED,
        action
    }
};

const getStockRecommendations = () =>{
    return{
        type: GET_STOCK_RECOMMENDATIONS,
        fetchRecommendation: true
    }
}

const getStockRecommendationsSuccess = (details) =>{
    return{
        type: GET_STOCK_RECOMMENDATIONS_SUCCESS,
        fetchRecommendation: false,
        recommendations: details
    }
}

const searchStock = (symbol) => (dispatch) => {
    dispatch(getStock(true));
    searchStockData(symbol).then((res)=>{
        dispatch(getStockData(res));
    }).catch((err)=>{
        dispatch(getStockDataFailed({message: "Stock Data fetch failed"}))
    })
    return null;
}

const stockHistory = (symbol) => (dispatch) => {
    stockHistoryData(symbol).then((res)=>{
        dispatch(getStockHistoryData(res));
    }).catch((err)=>{
        dispatch(getStockHistoryDataFailed({message: "Stock History fetch failed"}))
    })
    return null;
}

const buySingleStock = (symbol, quantity, user) => (dispatch) => {
    dispatch(buyStockStatus(true));
    buyStock(symbol, quantity, user).then((res)=>{
        dispatch(loadUserConfig(localStorage.getItem('coreX')))
        dispatch(buyStockSuccess(res));
        dispatch(buyStockStatus(false));
    }).catch((err)=>{
        console.log(err);
        dispatch(buyStockStatus(false));
        dispatch(buyStockFailed({message: err.message}))
    })
    return null;
}

const sellSingleStock = (symbol, quantity, user) => (dispatch) => {
    dispatch(buyStockStatus(true));
    sellStock(symbol, quantity, user).then((res)=>{
        dispatch(loadUserConfig(localStorage.getItem('coreX')))
        dispatch(sellStockSuccess(res));
        dispatch(buyStockStatus(false));
    }).catch((err)=>{
        dispatch(buyStockStatus(false));
        dispatch(sellStockFailed({message: "Sell stock Failed"}))
    })
    return null;
}

const fetchStockRecommendations = () => (dispatch) =>{
    dispatch(getStockRecommendations());
    getRecommendations().then(res=>{
        dispatch(getStockRecommendationsSuccess(res));
    }).catch((err)=>{
        console.log("Fetch Stock Recommendations failure ",err);
    })
}



export { searchStock, stockHistory, buySingleStock, sellSingleStock, buySellMessageUpdate, fetchStockRecommendations };