import { loadUser } from "../api/user";
import { userFoundFailure, userFoundSuccess } from "./auth";

const LOAD_USER_DETAILS = "LOAD_USER_DETAILS";
const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";
const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";

const loadUserConfigSuccess = (user) =>{
    return{
        type: LOAD_USER_DETAILS,
        user
    }
}

const fetchUserDetails = (isFetching) =>{
    return{
        type: FETCH_USER_DETAILS,
        fetchUser: isFetching
    }
}

export const loadUserConfig = (user_id) => ( dispatch) =>{
    dispatch(fetchUserDetails(true));
    loadUser(user_id).then((res)=>{
        dispatch(loadUserConfigSuccess(res));
        dispatch(userFoundSuccess());
        dispatch(fetchUserDetails(false))
    }).catch((err)=>{
        console.log("User config failed ",err);
        if(err.error)
            console.log("error");
        dispatch(fetchUserDetails(false))
        dispatch(userFoundFailure());
    })
}