const stockDetails = (state = {}, action) => {
  switch (action.type) {
    case "GET_STOCK":
      return {
        ...state,
        isStockFetching: action.isStockFetching
      }
    case "GET_STOCK_DETAILS_SUCCESS":
      return {
        ...state,
        details: action.details,
        stockStatus: "success",
        isStockFetching: false
      }
    case "GET_STOCK_HISTORY_DETAILS_SUCCESS":
      return {
        ...state,
        history: action.details,
        stockHistoryStatus: "success"
      }
    case "BUY_STOCK_SUCCESS":
      console.log(action)
      return {
        ...state,
        buy: action.details,
        buySellStatus: "success",
        buySellMessage: action.buySellMessage
      }
    case "BUY_STOCK":
      return {
        ...state,
        isBSAction: action.isBSAction,
        buySellMessage: action.buySellMessage
      }
    case "SELL_STOCK_SUCCESS":
      return {
        ...state,
        sell: action.details,
        buySellStatus: "success",
        buySellMessage: action.buySellMessage
      }
    case "GET_STOCK_DETAILS_FAILED":
      return {
        ...state,
        stockError: action.message,
        stockStatus: "failed",
        isStockFetching: false
      }
    case "GET_STOCK_HISTORY_DETAILS_FAILED":
      return {
        ...state,
        stockHistoryError: action.message,
        stockHistoryStatus: "failed"
      }
    case "BUY_STOCK_FAILED":
      return {
        ...state,
        buy: action.details,
        buySellStatus: "failed",
        buySellMessage: action.buySellMessage
      }
    case "SELL_STOCK_FAILED":
      console.log(action)
      return {
        ...state,
        buy: action.details,
        buySellStatus: "failed",
        buySellMessage: action.buySellMessage
      }
    case "UPDATE_BS_ACTION":
      return {
        ...state,
        isBSAction: action.action
      }
    case "BUY_SELL_MESSAGE_UPDATE":
      return {
        ...state,
        buySellMessage: action.buySellMessage
      }
    case "DEFAULT_STOCK":
      return {
        ...state,
        stock: action.stock
      }
    case "GET_STOCK_RECOMMENDATIONS":
      return {
        ...state,
        fetchRecommendations: true
      }
    case "GET_STOCK_RECOMMENDATIONS_SUCCESS":
      return {
        ...state,
        fetchRecommendations: false,
        recommendations: action.recommendations
      }
    default:
      return state;
  }
};

export default stockDetails;