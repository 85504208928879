import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userAccountRegister } from "../actions/auth";

const Signup = (props) => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [password, setPassword] = useState('');
    const [terms, setTerms] = useState(false);
    const [gender, setGender] = useState('male');
    const [male, setMale] = useState(true);
    const [female, setFemale] = useState(false);
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [showError, setShowError] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        const payload = {
            firstName,
            lastName,
            email,
            phone,
            city,
            password,
            state,
            zip,
            gender
        };

        dispatch(userAccountRegister(payload));
        // setTimeout(() => {
        //     navigate("/");
        // }, 1000)
    };

    const setGenderValue = (value) =>{
        if(value==='male'){
            setMale(true);
            setFemale(false);
            setGender("male")
        }else{
            setMale(false);
            setFemale(true);
            setGender("female")
        }
    }

    useEffect(() => {
        if (props.auth.error) {
            setShowError(true);
        } else {
            setShowError(false)
        }
    }, [props.auth.error])

    useEffect(() => {
        if (props.auth.status === 'success') {
            navigate('/')
        }
    }, [props.auth.status])

    return (
        <div>
            <nav class="absolute top-0 z-30 flex flex-wrap items-center justify-between w-full px-4 py-2 mt-6 mb-4 shadow-none lg:flex-nowrap lg:justify-start">
                <div class="container flex items-center justify-between py-0 flex-wrap-inherit">
                    <Link class="py-2.375 text-sm mr-4 ml-4 whitespace-nowrap font-bold text-white lg:ml-0" to="/dashboard"> Go to Market Dashboard </Link>

                </div>
            </nav>
            <main class="mt-0 transition-all duration-200 ease-soft-in-out">
                <section class="min-h-screen mb-32">
                    <div class="relative flex items-start pt-12 pb-56 m-4 overflow-hidden bg-center bg-cover min-h-50-screen rounded-xl" style={{ backgroundImage: "url('../assets/img/curved-images/curved14.jpg')" }}>
                        <span class="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-gray-900 to-slate-800 opacity-60"></span>
                        <div class="container z-10">
                            <div class="flex flex-wrap justify-center -mx-3">
                                <div class="w-full max-w-full px-3 mx-auto mt-0 text-center lg:flex-0 shrink-0 lg:w-5/12">
                                    <h1 class="mt-12 mb-2 text-white">Welcome!</h1>
                                    <p class="text-white">Welcome to the world of TradeStox! Sharpen your skills, build your confidence, and prepare for success in the market!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="flex flex-wrap -mx-3 -mt-48 md:-mt-56 lg:-mt-48">
                            <div class="w-full max-w-full px-3 mx-auto mt-0 md:flex-0 shrink-0 md:w-7/12 lg:w-5/12 xl:w-1/2">
                                <div class="relative z-0 flex flex-col min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                                    <div class="flex-auto p-6">
                                        <form role="form text-left" onSubmit={handleSubmit}>
                                            <div class="grid grid-cols-1 xl:grid-cols-2 gap-4">
                                                <div class="mb-4">
                                                    <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} class="text-sm focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow" placeholder="First Name" aria-label="First Name" pattern="[A-Za-z\s]+" aria-describedby="email-addon" required />
                                                </div>
                                                <div class="mb-4">
                                                    <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} class="text-sm focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow" placeholder="Last Name" aria-label="Last Name" pattern="[A-Za-z\s]+" aria-describedby="email-addon" required />
                                                </div>
                                                <div class="flex min-h-6 pl-6.92 mb-0.5 block">
                                                    <label className="mb-2 ml-1 font-normal cursor-pointer select-none text-sm text-slate-700">Gender </label>
                                                <div class="ml-3 min-h-6 pl-6.92 mb-0.5 block">
                                                    <input id="male" checked={male} onChange={(e) => setGenderValue("male")} class="w-4.92 h-4.92 ease-soft -ml-6.92 rounded-1.4 checked:bg-gradient-to-tl checked:from-gray-900 checked:to-slate-800 after:text-xxs after:font-awesome after:duration-250 after:ease-soft-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-200 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100" type="checkbox" value="" />
                                                    <label class="mb-2 ml-1 font-normal cursor-pointer select-none text-sm text-slate-700" for="terms">Male </label>
                                                </div>
                                                <div class="ml-2 min-h-6 pl-6.92 mb-0.5 block">
                                                    <input id="female" checked={female} onChange={(e) => setGenderValue("female")} class="w-4.92 h-4.92 ease-soft -ml-6.92 rounded-1.4 checked:bg-gradient-to-tl checked:from-gray-900 checked:to-slate-800 after:text-xxs after:font-awesome after:duration-250 after:ease-soft-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-200 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100" type="checkbox" value="" />
                                                    <label class="mb-2 ml-1 font-normal cursor-pointer select-none text-sm text-slate-700" for="terms">Female </label>
                                                </div></div>
                                                <div class="mb-4">
                                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} class="text-sm focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow" placeholder="Email" aria-label="Email" aria-describedby="email-addon" required />
                                                </div>
                                                <div class="mb-4">
                                                    <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} class="text-sm focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow" placeholder="Phone" aria-label="Phone" aria-describedby="email-addon" pattern="^\+?[1-9]\d{1,14}$|^\d{10}$" minlength="10" maxLength={10} required />
                                                </div>

                                                <div class="mb-4">
                                                    <input type="text" value={city} onChange={(e) => setCity(e.target.value)} class="text-sm focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow" placeholder="City" aria-label="City" pattern="[A-Za-z\s]+" aria-describedby="password-addon" required />
                                                </div>
                                                <div class="mb-4">
                                                    <input type="text" value={state} onChange={(e) => setState(e.target.value)} class="text-sm focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow" placeholder="State" aria-label="State" pattern="[A-Za-z\s]+" aria-describedby="password-addon" required />
                                                </div>
                                                <div class="mb-4">
                                                    <input type="text" value={zip} onChange={(e) => setZip(e.target.value)} class="text-sm focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow" placeholder="Pin Code" aria-label="Pin Code" pattern="\d+" aria-describedby="password-addon" minlength="6" maxLength={6}  required />
                                                </div>
                                                <div class="mb-4">
                                                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} class="text-sm focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow" placeholder="Password" aria-label="Password" aria-describedby="password-addon" minlength="8" required />
                                                </div>
                                            </div>

                                            <div class="min-h-6 pl-6.92 mb-0.5 block">
                                                <input id="terms" checked={terms} onChange={(e) => setTerms(e.target.checked)} class="w-4.92 h-4.92 ease-soft -ml-6.92 rounded-1.4 checked:bg-gradient-to-tl checked:from-gray-900 checked:to-slate-800 after:text-xxs after:font-awesome after:duration-250 after:ease-soft-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-200 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100" type="checkbox" value="" required />
                                                <label class="mb-2 ml-1 font-normal cursor-pointer select-none text-sm text-slate-700" for="terms"> I agree to the <a href="javascript:;" class="font-bold text-slate-700">Terms and Conditions</a> </label>
                                            </div>

                                            <div class="text-center">
                                                <button class="inline-block w-full px-6 py-3 mt-6 mb-2 font-bold text-center text-white uppercase align-middle transition-all bg-transparent border-0 rounded-lg cursor-pointer active:opacity-85 hover:scale-102 hover:shadow-soft-xs leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 bg-gradient-to-tl from-gray-900 to-slate-800 hover:border-slate-700 hover:bg-slate-700 hover:text-white" >Sign up</button>
                                                {showError ? <span class='text-xs md:text-sm text-red-600 text-center justify-center mb-1 flex'>{props.auth.error}</span> : ""}
                                            </div>

                                            <p class="mt-4 mb-0 leading-normal text-sm">Already have an account? <a href="/signin" class="font-bold text-slate-700">Sign in</a></p>
                                        </form>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

const mapStateToProps = state => {
    console.log("state-login->>", state);
    return {
        auth: state.auth || {}
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);