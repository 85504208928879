import { baseUrl } from "../constants";

export const userLogin = async(user)=>{
    const url = `${baseUrl}/v1/corex/login`;
    const res = await fetch(url,{
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:3000',
        },
        body: JSON.stringify({
           ...user
        })
    });
    
    if(!res.ok){
        const message = `Error ${res.status}`
        throw new Error(message);
    }
    let response = await res.json();
    return response;
}

export const registerUser = async(user)=>{
    const url = `${baseUrl}/v1/corex/register`;
    const res = await fetch(url,{
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:3000'
        },
        body: JSON.stringify({
            ...user
        })
    });
    
    if(!res.ok){
        const message = `Error ${res.status}`
        throw new Error(message);
    }
    let response = await res.json();
    return response;
}