import _ from 'lodash';
import http from 'axios';
import { baseUrl } from '../constants';
import Cookies from 'universal-cookie';

export const searchStockData = async (stock) => {
    let url = `${baseUrl}/v1/corex/getStock`;
    const res = await fetch(url, {
        method: "POST",
        //mode:"no-cors",
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:3000',
        },
        body: JSON.stringify({
            "id": stock
        })
    });
    if (!res.ok) {
        const message = `Error ${res.status}`
        throw new Error(message);
    }
    let r = await res.json();
    return r;
}

export const stockHistoryData = async (stock) => {
    let url = `${baseUrl}/v1/corex/getStockHistory`;
    const res = await fetch(url, {
        method: "POST",
        //mode:"no-cors",
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:3000'
        },
        body: JSON.stringify({
            "id": stock
        })
    });
    if (!res.ok) {
        const message = `Error ${res.status}`
        throw new Error(message);
    }
    let r = await res.json();
    return r;
}

export const buyStock = async (stock, quantity, user) => {
    const cookie = new Cookies();
    let url = `${baseUrl}/v1/corex/buy`;
    const res = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:3000',
            'jwt': cookie.get('coreX_access_token')
        },
        body: JSON.stringify({
            stock_id: stock,
            quantity: quantity,
            user_id: user
        })
    });
    if (!res.ok) {
        const message = `Error ${res.status}`
        throw new Error(message);
    }
    let r = await res.json();
    return r;
}

export const sellStock = async (stock, quantity, user) => {
    const cookie = new Cookies();
    let url = `${baseUrl}/v1/corex/sell`;
    const res = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:3000',
            'jwt': cookie.get('coreX_access_token')
        },
        body: JSON.stringify({
            stock_id: stock,
            quantity: quantity,
            user_id: user
        })
    });
    if (!res.ok) {
        const message = `Error ${res.status}`
        throw new Error(message);
    }
    let r = await res.json();
    return r;
}

export const getIndices = async () => {
    console.log("get overview")
    let url = `${baseUrl}/v1/corex/overview`;
    const res = await fetch(url, {
        method: "POST",
        //mode:"no-cors",
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:3000',
        },
        body: JSON.stringify({
            "id": "stocks"
        })
    });
    if (!res.ok) {
        const message = `Error ${res.status}`
        throw new Error(message);
    }
    let r = await res.json();
    return r;
}

export const getRecommendations = async () => {
    console.log("get overview")
    let url = `${baseUrl}/v1/corex/recommendations`;
    const res = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:3000',
        },
        body: JSON.stringify({
            "id": "stocks"
        })
    });
    if (!res.ok) {
        const message = `Error ${res.status}`
        throw new Error(message);
    }
    let r = await res.json();
    return r;
}

function _post(options) {

    const o = options;
    return new Promise((resolve, reject) => {
        http({
            url: o.url,
            method: 'post',
            headers: _.extend(
                {},
                {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                o.headers,
            ),
            params: o.params,
            data: o.data,
            responseType: o.responseType || 'json',
        })
            .then((json) => {
                resolve(json.data);
            })
            .catch((response) => {
                reject(response);
            });
    });
}