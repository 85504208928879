import { getIndices } from "../api/stocks";

const GET_INDICES = 'GET_INDICES';
const GET_INDICES_SUCCESS = 'GET_INDICES_SUCCESS'; 

const getIndicesSuccess = (details) =>{
    return{
        type: GET_INDICES_SUCCESS,
        indices: details
    }
}

const fetchIndices = (details) =>{
    return{
        type: GET_INDICES
    }
}

export const getIndicesDetails = () => (dispatch) =>{
    dispatch(fetchIndices());
    getIndices().then((res)=>{
        dispatch(getIndicesSuccess(res));
    }).catch((err)=>{
        console.log("Get indices details failed");
    })
}
