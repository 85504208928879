import React, { useEffect, useRef, useState } from "react";
import LiveCandlestickChart from "../components/LiveCandlestickChart";
import { stockList as stockIntial, defaultStock } from "../constants";
import { overViewItems, redItems, greenItems, normalItems, depthItems, redDepthItems, greenDepthItems, normalDepthItems, depthValueItems, stocks } from "../constants";
import { buySellMessageUpdate, buySingleStock, defaultStockUpdate, fetchStockRecommendations, searchStock, sellSingleStock, stockHistory, updateBSStatus } from "../actions/stock";
import { connect, useDispatch } from "react-redux";
import { Link, useAsyncError } from "react-router-dom";
import Alert from "./Alert";
import { loadUserConfig } from "../actions/user";

const Dashboard = (props) => {

    const [showBuy, setShowBuy] = useState(false);
    const [showSell, setShowSell] = useState(false);
    const [showPredict, setShowPredict] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [stockList, setStockList] = useState(stockIntial);
    const [stock, setStock] = useState(props.defaultStock);
    const [showBuySell, setBuySell] = useState(false);
    const [quantity, setQuantity] = useState(0)
    const [sellQuantity, setSellQuantity] = useState(0);
    const [buySellAction, setBuySellAction] = useState(true);
    const [results, setResults] = useState([]);
    const [showStockError, setShowStockError] = useState(false);
    const [showStockHistoryError, setShowStockHistoryError] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const dispatch = useDispatch();
    const latestProps = useRef(props);

    const changeStock = () => {
        setShowSearch(!showSearch);
    }

    const handleShowAlert = () => {
        console.log("show alert")
        setShowAlert(true);
        setShowBuy(false);
        setShowPredict(false);
        setShowSell(false);
        setTimeout(() => {
            console.log("show alert false")
            setShowAlert(false);
            dispatch(buySellMessageUpdate("-"))
        }, 5000);
    };

    const clearBuySell = () => {
        setShowBuy(false);
        setShowPredict(false);
        setShowSell(false);
    }

    const handleSearch = (value) => {
        let input = value.target.value
        if (input && input.length > 2) {
            let st = stockList.filter((item) => item && item.value.includes(input.toUpperCase()));
            setStockList(st)
        } else {
            setStockList(stockIntial);
        }
    }

    const handleShowActions = (action, status) => {
        if (action === "buy") {
            setShowBuy(status);
        }
        else if (action === "sell") {
            setShowSell(status)
        }
        else if (action === "predict")
            setShowPredict(status);
    }

    const clearSearch = () => {
        setShowSearch(false);
        setStockList(stockIntial)
    }

    const selectStock = (stock) => {
        setStock(stock);
        setShowSearch(false);
        setResults(stockIntial)
        dispatch(searchStock(stock));
        dispatch(stockHistory(stock));
        dispatch(defaultStockUpdate(stock));
    }

    const handleBuyClick = (value, buy) => {
        setBuySell(value);

    }

    const handleBuySell = (value) => {
        console.log("handle buy sell", props, props.user);
        if (props.user && props.user.user) {
            if (value)
                dispatch(buySingleStock(stock, quantity, props.user.user.id));
            else
                dispatch(sellSingleStock(stock, sellQuantity, props.user.user.id));
        }
    }

    function isInTimePeriod() {
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();

        // Check if the current time is between 9:15 AM and 3:30 PM
        return (currentHour > 9 || (currentHour === 9 && currentMinute >= 15)) &&
            (currentHour < 15 || (currentHour === 15 && currentMinute <= 30));
    }

    useEffect(() => {
        dispatch(searchStock(props.defaultStock));
        dispatch(stockHistory(props.defaultStock));
        dispatch(fetchStockRecommendations());
        dispatch(loadUserConfig(localStorage.getItem('coreX')));


        const fetchData = () => {
            if (isInTimePeriod()) {
                const currentProps = latestProps.current;
                dispatch(searchStock(currentProps.defaultStock));
                dispatch(fetchStockRecommendations());
                console.log("P ", currentProps);
            }
        };
        const fetchHistoryData = () => {
            if (isInTimePeriod) {
                const currentProps = latestProps.current;
                dispatch(stockHistory(currentProps.defaultStock));
            }
        };

        // Set interval to call the function every 5 seconds
        const intervalId = setInterval(fetchData, 5000);
        const intervalHistoryId = setInterval(fetchHistoryData, 60000);

        // Cleanup interval when the component is unmounted or the effect runs again
        return () => { clearInterval(intervalId); clearInterval(intervalHistoryId) };
        console.log("P ", props);
    }, []);

    useEffect(() => {
        if (props.stockStatus === 'failed') {
            setShowStockError(true);
        } else {
            setShowStockError(false);
        }
    }, [props.stockStatus])

    useEffect(() => {
        if (props.stockHistoryStatus === 'failed') {
            setShowStockHistoryError(true);
        } else {
            setShowStockHistoryError(false);
        }
    }, [props.stockHistoryStatus])

    useEffect(() => {
        console.log(props.buySellMessage)
        if (props.buySellMessage != "-")
            handleShowAlert();
    }, [props.buySellMessage])

    useEffect(() => {
        latestProps.current = props;
    }, [props]);

    return (
        <div >
            <div class="w-full px-6 py-6 mx-auto">
                <div class={`fixed  z-100 overflow-y-auto top-15 w-full left-0 ${showSearch ? "block" : "hidden"}`} id="modal">
                    <div class="flex shadow-soft-3xl items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 ">
                        <div class="w-full max-w-screen-xl mx-auto px-0 ">
                            <div class="flex justify-center p-4 px-3 py-10">
                                <div class="w-full max-w-md">
                                    <div class="bg-gray-50 shadow-md rounded-lg px-3 py-2 mb-4">
                                        <div class="block text-gray-700 text-lg font-semibold py-2 px-2">
                                            Stock List
                                        </div>
                                        <div class="flex items-center bg-gray-200 rounded-md">
                                            <input
                                                class="w-full rounded-md bg-gray-200 font-sans font-semibold leading-normal text-sm focus:outline-none py-2 px-2"
                                                id="search" type="text" placeholder="Search" onChange={handleSearch} />
                                        </div>

                                        <div class="py-3 text-sm h-64 overflow-auto">
                                            {
                                                stockList.map((item) =>
                                                    <div class=" cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2 text-left" onClick={() => { selectStock(item.key) }}>
                                                        <span class="flex-grow font-medium px-2 hover:bg-blue-100" >{item.value}</span>
                                                    </div>
                                                )
                                            }

                                        </div>
                                        <div class="block bg-gray-200 text-sm text-right py-2 px-3 -mx-3 -mb-2 rounded-b-lg">
                                            <button class="hover:text-gray-600 text-gray-500 font-bold py-2 px-4" onClick={() => { clearSearch() }}>
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap -mx-3">
                    {!props.loggedIn ? <div class="w-full max-w-full px-3 mb-6  sm:flex-none xl:mb-0 ">
                        <div class="relative flex min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border">
                            <div class="flex-auto p-4">
                                <div class="flex flex-row -mx-3">
                                    <div class="flex w-2/3 max-w-full px-3  items-center">
                                        <div>
                                            <h6 class="mb-0 font-bold">
                                                Login to your account to LEARN more. PRACTICE more. EARN more.
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="px-3 text-right basis-1/3">
                                        <Link class="inline-block w-auto px-6 py-3  font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg select-none shadow-soft-md bg-150 bg-x-25 leading-pro text-xs bg-gradient-to-tl from-purple-700 to-pink-500 hover:shadow-soft-2xl hover:scale-102" to="/signin">Sign In</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                        <>
                            {/* <!-- card1 --> */}
                            <div class="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
                                <div class="relative flex flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border">
                                    <div class="flex-auto p-4">
                                        <div class="flex flex-row -mx-3">
                                            <div class="flex-none w-2/3 max-w-full px-3">
                                                <div>
                                                    <p class="mb-0 font-sans font-semibold leading-normal text-sm">Profit/ Loss</p>
                                                    <h5 class="mb-0 font-bold">
                                                        {props.fetchingUser ? <p>Loading...</p> : <>{(props.user && props.user.plData && props.user.plData.cur_amt) ? props.user.plData.cur_amt : 0}</>}
                                                        <span class={`leading-normal text-sm font-weight-bolder ${props.user && props.user.plData && props.user.plData.pl > 0 ? "text-lime-500" : "text-red-500"}`}> {props.fetchingUser ? <p></p> : <>{props.user && props.user.plData && props.user.plData.pl}</>}</span>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div class="px-3 text-right basis-1/3">
                                                <div class="inline-block w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-purple-700 to-pink-500">
                                                    <i class="ni leading-none ni-money-coins text-lg relative top-3.5 text-white"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- card2 --> */}
                            <div class="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
                                <div class="relative flex flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border">
                                    <div class="flex-auto p-4">
                                        <div class="flex flex-row -mx-3">
                                            <div class="flex-none w-2/3 max-w-full px-3">
                                                <div>
                                                    <p class="mb-0 font-sans font-semibold leading-normal text-sm">Invested Amount</p>
                                                    <h5 class="mb-0 font-bold">
                                                        {props.fetchingUser ? <p>Loading...</p> : <>{(props.user && props.user.plData && props.user.plData.hl_amt) ? props.user.plData.hl_amt : 0}</>}
                                                    </h5>
                                                </div>
                                            </div>
                                            <div class="px-3 text-right basis-1/3">
                                                <div class="inline-block w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-purple-700 to-pink-500">
                                                    <i class="ni leading-none ni-world text-lg relative top-3.5 text-white"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- card3 --> */}
                            <div class="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
                                <div class="relative flex flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border">
                                    <div class="flex-auto p-4">
                                        <div class="flex flex-row -mx-3">
                                            <div class="flex-none w-2/3 max-w-full px-3">
                                                <div>
                                                    <p class="mb-0 font-sans font-semibold leading-normal text-sm">Available Amount</p>
                                                    <h5 class="mb-0 font-bold">
                                                        {props.fetchingUser ? <p>Loading...</p> : <>{props.user && props.user.virtual_wallet && props.user.virtual_wallet.available_amount}</>}
                                                    </h5>
                                                </div>
                                            </div>
                                            <div class="px-3 text-right basis-1/3">
                                                <div class="inline-block w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-purple-700 to-pink-500">
                                                    <i class="ni leading-none ni-paper-diploma text-lg relative top-3.5 text-white"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- card4 --> */}
                            <div class="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/4">
                                <div class="relative flex flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border">
                                    <div class="flex-auto p-4">
                                        <div class="flex flex-row -mx-3">
                                            <div class="flex-none w-2/3 max-w-full px-3">
                                                <div>
                                                    <p class="mb-0 font-sans font-semibold leading-normal text-sm">Coins</p>
                                                    <h5 class="mb-0 font-bold">
                                                        {props.fetchingUser ? <p>Loading...</p> : <>{props.user && props.user.virtual_wallet && props.user.virtual_wallet.available_tournament_coins}</>}
                                                    </h5>
                                                </div>
                                            </div>
                                            <div class="px-3 text-right basis-1/3">
                                                <div class="inline-block w-12 h-12 text-center rounded-lg bg-gradient-to-tl from-purple-700 to-pink-500">
                                                    <i class="ni leading-none ni-cart text-lg relative top-3.5 text-white"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                </div>
                <div class="flex flex-wrap mt-6 -mx-3">
                    <div class="w-full max-w-full px-3 mt-0 lg:w-7/12 lg:flex-none">
                        <div class="border-black/12.5 shadow-soft-xl relative z-20 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                            {/* <div class="border-black/12.5 mb-0 rounded-t-2xl border-b-0 border-solid bg-white p-6 pb-0">
                                <h6>Sales overview</h6>
                                <p class="leading-normal text-sm">
                                    <i class="fa fa-arrow-up text-lime-500"></i>
                                    <span class="font-semibold">4% more</span> in 2021
                                </p>
                            </div> */}
                            <div class="flex-auto p-4">
                                <div>
                                    <LiveCandlestickChart />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-5/12 lg:flex-none">
                        <div class="h-full border-black/12.5 shadow-soft-xl relative z-20 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                            <div class="flex-auto p-4">
                                <div class="py-4 pr-1 mb-4 bg-gradient-to-tl from-gray-900 to-slate-800 rounded-xl">
                                    <div class="w-auto max-w-full px-3 mb-6 sm:flex-none xl:mb-0">
                                        <div class="relative flex w-auto min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border">
                                            <div class="flex-auto p-4">
                                                <div class="flex flex-row -mx-3">
                                                    <div class="flex-none w-full max-w-full px-3">
                                                        <div>
                                                            <p class="mb-0 font-sans font-semibold leading-normal text-sm">NSE Stock <span className="text-xs text-blue-500 cursor-pointer pl-4" onClick={() => { setShowSearch(true) }}>Change stock</span></p>
                                                            <h5 class="mb-0 font-bold">
                                                                {props.details ? props.details.symbol : "NA"}
                                                            </h5>
                                                            <h6 class={`mb-0 font-bold ${(props.details && props.details.changePercentage > 0) ? "text-green-500" : "text-red-500"}`}>
                                                                {(props.details) ? props.details.price : "NA"}
                                                                <span class={`leading-normal text-sm font-weight-bolder${(props.details && props.details.changePercentage > 0) ? "text-green-500" : "text-red-500"}`}>{(props.details) ? "(" + props.details.changePercentage + ")" : "NA"}</span>
                                                            </h6>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {props.isBSAction ?
                                        <div class="loader">
                                            <div class="circle">
                                                <div class="dot"></div>
                                                <div class="outline"></div>
                                            </div>
                                            <div class="circle">
                                                <div class="dot"></div>
                                                <div class="outline"></div>
                                            </div>
                                            <div class="circle">
                                                <div class="dot"></div>
                                                <div class="outline"></div>
                                            </div>
                                            <div class="circle">
                                                <div class="dot"></div>
                                                <div class="outline"></div>
                                            </div>
                                        </div> : <>
                                            {(!showBuy && !showSell && !showPredict) && <div class="flex w-full max-w-full px-3 space-x-2">
                                                <div class="inline-block w-full px-6 py-3 my-4 cursor-pointer font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg select-none shadow-soft-md bg-150 bg-x-25 leading-pro text-xs bg-gradient-to-tl from-green-700 to-green-400 hover:shadow-soft-2xl hover:scale-102" onClick={() => { handleShowActions("buy", true) }}>Buy</div>
                                                <div class="inline-block w-full px-6 py-3 my-4 cursor-pointer font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg select-none shadow-soft-md bg-150 bg-x-25 leading-pro text-xs bg-gradient-to-tl from-gray-700 to-slate-500 hover:shadow-soft-2xl hover:scale-102" onClick={() => { handleShowActions("predict", true) }}>Predict</div>
                                                <div class="inline-block w-full px-6 py-3 my-4 cursor-pointer font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg select-none shadow-soft-md bg-150 bg-x-25 leading-pro text-xs bg-gradient-to-tl from-red-700 to-red-400 hover:shadow-soft-2xl hover:scale-102" onClick={() => { handleShowActions("sell", true) }}>Sell</div>
                                            </div>}
                                            {showBuy && <div>
                                                {props.loggedIn ? <div class="mb-4 items-center justify-center flex space-x-5">
                                                    <input type="text" class="text-sm focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-1/2 appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow" placeholder="Quantity" aria-label="Name" aria-describedby="email-addon" onChange={(e) => { setQuantity(e.target.value) }} />
                                                    <div class="inline-block w-auto px-6 py-3 my-4 cursor-pointer font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg select-none shadow-soft-md bg-150 bg-x-25 leading-pro text-xs bg-gradient-to-tl from-green-700 to-green-400 hover:shadow-soft-2xl hover:scale-102" onClick={() => { handleBuySell(true) }}>Buy</div>
                                                    <span className="w-6 items-center justify-center cursor-pointer" onClick={() => { clearBuySell() }}><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"
                                                            fill="white"
                                                        />
                                                    </svg></span>
                                                </div> : <div className="mb-4 items-center justify-center flex space-x-5">
                                                    <span className="text-sm text-white font-semibold items-center p-2">Please SignIn to start your virtual trade <Link to="/signin" className="font-bold">SignIn</Link></span>
                                                    <span className="w-6 items-center justify-center cursor-pointer" onClick={() => { clearBuySell() }}><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"
                                                            fill="white"
                                                        />
                                                    </svg></span>
                                                </div>}
                                            </div>}
                                            {showSell && <div>
                                                {props.loggedIn ? <div class="mb-4 items-center justify-center flex space-x-5">
                                                    <input type="text" class="text-sm focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-1/2 appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow" placeholder="Quantity" aria-label="Name" aria-describedby="email-addon" onChange={(e) => { setSellQuantity(e.target.value) }} />
                                                    <div class="inline-block w-auto px-6 py-3 my-4 cursor-pointer font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg select-none shadow-soft-md bg-150 bg-x-25 leading-pro text-xs bg-gradient-to-tl from-red-700 to-red-400 hover:shadow-soft-2xl hover:scale-102" onClick={() => { handleBuySell(false) }}>Sell</div>
                                                    <span className="w-6 items-center justify-center cursor-pointer" onClick={() => { clearBuySell() }}><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"
                                                            fill="white"
                                                        />
                                                    </svg></span>
                                                </div> : <div className="mb-4 items-center justify-center flex space-x-5">
                                                    <span className="text-sm text-white font-semibold items-center p-2">Please SignIn to start your virtual trade <Link to="/signin" className="font-bold">SignIn</Link></span>
                                                    <span className="w-6 items-center justify-center cursor-pointer" onClick={() => { clearBuySell() }}><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"
                                                            fill="white"
                                                        />
                                                    </svg></span>
                                                </div>}
                                            </div>}
                                            {showPredict && <div>
                                                {props.loggedIn ? <div class="mb-4 items-center justify-center flex space-x-5">
                                                    <input type="text" class="text-sm focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-1/2 appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow" placeholder="Quantity" aria-label="Name" aria-describedby="email-addon" />
                                                    <div class="inline-block w-auto px-6 py-3 my-4 cursor-pointer font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg select-none shadow-soft-md bg-150 bg-x-25 leading-pro text-xs bg-gradient-to-tl from-gray-700 to-slate-500 hover:shadow-soft-2xl hover:scale-102" onClick={() => { handleShowActions("predict", false) }}>Predict</div>
                                                    <span className="w-6 items-center justify-center cursor-pointer" onClick={() => { clearBuySell() }}><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"
                                                            fill="white"
                                                        />
                                                    </svg></span>
                                                </div> : <div className="mb-4 items-center justify-center flex space-x-5">
                                                    <span className="text-sm text-white font-semibold items-center p-2 w-1/2">Please SignIn to start your virtual trade <Link to="/signin" className="font-bold">SignIn</Link></span>
                                                    <span className="w-6 items-center justify-center cursor-pointer" onClick={() => { clearBuySell() }}><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"
                                                            fill="white"
                                                        />
                                                    </svg></span>
                                                </div>}

                                            </div>} </>}
                                    {
                                        showAlert && <div className="flex font-semibold text-white justify-center text-sm"><span>{props.buySellMessage}</span></div>
                                    }
                                </div>
                                <h6 class="mt-6 mb-0 ml-2">Suggested Stocks</h6>
                                <p class="ml-2 leading-normal text-sm"> Expert Recommendation</p>
                                <div class="w-full px-6 mx-auto max-w-screen-2xl rounded-xl">
                                    <div class="flex flex-wrap mt-0 -mx-3">
                                        {props.recommendations && props.recommendations.map((item) =>
                                           <> { item && <div class="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0 cursor-pointer" onClick={() => { selectStock(item.instrument_token) }}>
                                                <div class="flex mb-2">
                                                    <div class={`flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current shadow-soft-2xl ${item.change > 0 ? "bg-gradient-to-tl from-green-700 to-green-400" : "bg-gradient-to-tl from-red-700 to-red-400"} text-neutral-900`}>

                                                    </div>
                                                    <p class="mt-1 mb-0 font-semibold leading-tight text-xs">{item.price}</p>
                                                </div>
                                                <h6 class="font-bold text-sm">{item.symbol}</h6>
                                                <div class="text-xs h-0.75 flex w-full overflow-visible rounded-lg bg-gray-200">
                                                    <div class={`duration-600 ease-soft -mt-0.38 -ml-px flex h-1.5 w-full flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg ${item.change > 0 ? "bg-gradient-to-tl from-green-700 to-green-400" : "bg-gradient-to-tl from-red-700 to-red-400"}  text-center text-white transition-all`} role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div> } </>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = state => {
    console.log("state ", state);
    return {
        details: state.stockDetails.details || {},
        stockStatus: state.stockDetails.stockStatus || {},
        stockHistoryStatus: state.stockDetails.stockHistoryStatus || {},
        buySellStatus: state.stockDetails.buySellStatus || {},
        buySellError: state.stockDetails.buySellError || {},
        user: state.user.user || {},
        fetchingUser: state.user.fetchingUser || false,
        isBuying: state.stockDetails.isBuying || false,
        isBSAction: state.stockDetails.isBSAction || false,
        buySellMessage: state.stockDetails.buySellMessage || "",
        defaultStock: state.stockDetails.stock || defaultStock,
        isStockFetching: state.stockDetails.isStockFetching || false,
        recommendations: state.stockDetails.recommendations || [],
        loggedIn: state.auth.logIn || false
    }
}

const mapDispatchToProps = dispatch => {
    return {
        searchStock: param => dispatch(searchStock(param)),
        stockHistory: param => dispatch(stockHistory(param)),
        buySingleStock: param => dispatch(buySingleStock(param)),
        sellSingleStock: param => dispatch(sellSingleStock(param)),
        fetchRecommendations: param => dispatch(fetchStockRecommendations())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);