import React, { useEffect, useRef, useState } from "react";
import { userAccountLogout } from "../actions/auth";
import { Link, Outlet } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { getIndicesDetails } from "../actions/layout";
import { baseUrl } from "../constants";

const Layout = (props) => {
    const sidenavTrigger = useRef(null);
    const sidenavClose = useRef(null);
    const asideRef = useRef(null);
    const dispatch = useDispatch();
    const [scrollWidth, setScrollWidth] = useState(0);
    const marqueeRef = useRef(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [menu, setMenu] = useState("Dashboard");
    const stockData = [
        { symbol: 'AAPL', price: 175.60, change: +1.23 },
        { symbol: 'GOOGL', price: 2903.78, change: -2.54 },
        { symbol: 'AMZN', price: 3342.88, change: +4.32 },
        { symbol: 'MSFT', price: 319.12, change: -1.56 },
    ];

    const userLogout = () => {
        dispatch(userAccountLogout());
        setLoggedIn(!loggedIn);
    }


    useEffect(() => {
        dispatch(getIndicesDetails());
        var sidenav = asideRef.current;
        var sidenav_trigger = sidenavTrigger.current;
        var sidenav_close_button = sidenavClose.current;
        var burger = sidenav_trigger.firstElementChild;
        var top_bread = burger.firstElementChild;
        var bottom_bread = burger.lastElementChild;
        const sl = () => {
            sidenav_close_button.classList.toggle("hidden");
            sidenav.classList.toggle("translate-x-0");
            sidenav.classList.toggle("shadow-soft-xl");

            top_bread.classList.toggle("translate-x-[5px]");
            bottom_bread.classList.toggle("translate-x-[5px]");

        }
        sidenav_trigger.addEventListener("click", sl);
        sidenav_close_button.addEventListener("click", function () {
            sidenav_trigger.click();
        });

        let w = window.addEventListener("click", function (e) {
            if (!sidenav_trigger.contains(e.target)) {
                if (sidenav.classList.contains("translate-x-0")) {
                    sidenav_trigger.click();
                }
            }
        });

        return () => {
            window.removeEventListener('click', w);
            sidenav_trigger.removeEventListener('click', sl);
        };
    }, []);

    useEffect(()=>{
        if(props.logIn === false){
            setMenu('Dashboard');
        }
    },[props.logIn])

    return (
        <>
            <aside class="max-w-62.5 ease-nav-brand z-990 fixed inset-y-0 my-4 ml-4 block w-full -translate-x-[130%] flex-wrap items-center justify-between overflow-y-auto rounded-2xl border-0 bg-white p-0 antialiased shadow-none transition-transform duration-200 xl:left-0 xl:translate-x-0 xl:bg-transparent" ref={asideRef}>
                <div class="h-19.5">
                    <i class="absolute top-0 right-0 hidden p-4 opacity-50 cursor-pointer fas fa-times text-slate-400" ref={sidenavClose}></i>
                    <Link to="/" class="block px-8 py-6 m-0 text-sm whitespace-nowrap text-slate-700">
                        <img src={`${baseUrl}//assets/img/logo-ct.png`} class="inline h-full max-w-full transition-all duration-200 ease-nav-brand max-h-8" alt="main_logo" />
                        <span class="ml-1 font-semibold transition-all duration-200 ease-nav-brand">   TradeStox  </span>
                    </Link>
                </div>

                <hr class="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent" />

                <div class="items-center block w-auto max-h-screen overflow-auto h-sidenav grow basis-full">
                    <ul class="flex flex-col pl-0 mb-0">
                        <li class="mt-0.5 w-full" onClick={() => { setMenu("Dashboard") }}>
                            <Link to="/dashboard" class={`py-2.7  text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap ${menu === 'Dashboard' && "rounded-lg shadow-soft-xl bg-white"} px-4 font-semibold text-slate-700 transition-colors`} >
                                <div class={`${menu === 'Dashboard' && "bg-gradient-to-tl from-purple-700 to-pink-500"} shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5`}>
                                    <svg width="12px" height="12px" viewBox="0 0 45 40" version="1.1">
                                        <title>shop</title>
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g transform="translate(-1716.000000, -439.000000)" fill="#FFFFFF" fillRule="nonzero">
                                                <g transform="translate(1716.000000, 291.000000)">
                                                    <g transform="translate(0.000000, 148.000000)">
                                                        <path
                                                            className={`${menu !== 'Dashboard' && "fill-slate-800"} opacity-60`}
                                                            d="M46.7199583,10.7414583 L40.8449583,0.949791667 C40.4909749,0.360605034 39.8540131,0 39.1666667,0 L7.83333333,0 C7.1459869,0 6.50902508,0.360605034 6.15504167,0.949791667 L0.280041667,10.7414583 C0.0969176761,11.0460037 -1.23209662e-05,11.3946378 -1.23209662e-05,11.75 C-0.00758042603,16.0663731 3.48367543,19.5725301 7.80004167,19.5833333 L7.81570833,19.5833333 C9.75003686,19.5882688 11.6168794,18.8726691 13.0522917,17.5760417 C16.0171492,20.2556967 20.5292675,20.2556967 23.494125,17.5760417 C26.4604562,20.2616016 30.9794188,20.2616016 33.94575,17.5760417 C36.2421905,19.6477597 39.5441143,20.1708521 42.3684437,18.9103691 C45.1927731,17.649886 47.0084685,14.8428276 47.0000295,11.75 C47.0000295,11.3946378 46.9030823,11.0460037 46.7199583,10.7414583 Z"
                                                        ></path>
                                                        <path className={`${menu !== 'Dashboard' && "fill-slate-800"}`}
                                                            d="M39.198,22.4912623 C37.3776246,22.4928106 35.5817531,22.0149171 33.951625,21.0951667 L33.92225,21.1107282 C31.1430221,22.6838032 27.9255001,22.9318916 24.9844167,21.7998837 C24.4750389,21.605469 23.9777983,21.3722567 23.4960833,21.1018359 L23.4745417,21.1129513 C20.6961809,22.6871153 17.4786145,22.9344611 14.5386667,21.7998837 C14.029926,21.6054643 13.533337,21.3722507 13.0522917,21.1018359 C11.4250962,22.0190609 9.63246555,22.4947009 7.81570833,22.4912623 C7.16510551,22.4842162 6.51607673,22.4173045 5.875,22.2911849 L5.875,44.7220845 C5.875,45.9498589 6.7517757,46.9451667 7.83333333,46.9451667 L19.5833333,46.9451667 L19.5833333,33.6066734 L27.4166667,33.6066734 L27.4166667,46.9451667 L39.1666667,46.9451667 C40.2482243,46.9451667 41.125,45.9498589 41.125,44.7220845 L41.125,22.2822926 C40.4887822,22.4116582 39.8442868,22.4815492 39.198,22.4912623 Z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <span class="ml-1 duration-300 opacity-100 pointer-events-none ease-soft">Dashboard</span>
                            </Link>
                        </li>

                        {/* <li class="mt-0.5 w-full" onClick={() => { setMenu("Tournament") }}>
                            <Link to="/tournament" class={`py-2.7 text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 transition-colors ${menu === 'Tournament' && "rounded-lg shadow-soft-xl bg-white"} `}>
                                <div class={`${menu === 'Tournament' && "bg-gradient-to-tl from-purple-700 to-pink-500"} shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5`}>
                                    <svg
                                        width={12}
                                        height={12}
                                        viewBox="0 0 42 42"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <title>office</title>
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g transform="translate(-1869.000000, -293.000000)" fill="#FFFFFF" fillRule="nonzero">
                                                <g transform="translate(1716.000000, 291.000000)">
                                                    <g transform="translate(153.000000, 2.000000)">
                                                        <path
                                                            className={`${menu !== 'Tournament' && "fill-slate-800"} opacity-60`}
                                                            d="M12.25,17.5 L8.75,17.5 L8.75,1.75 C8.75,0.78225 9.53225,0 10.5,0 L31.5,0 C32.46775,0 33.25,0.78225 33.25,1.75 L33.25,12.25 L29.75,12.25 L29.75,3.5 L12.25,3.5 L12.25,17.5 Z"
                                                        />
                                                        <path
                                                            className={`${menu !== 'Tournament' && "fill-slate-800"}`}
                                                            d="M40.25,14 L24.5,14 C23.53225,14 22.75,14.78225 22.75,15.75 L22.75,38.5 L19.25,38.5 L19.25,22.75 C19.25,21.78225 18.46775,21 17.5,21 L1.75,21 C0.78225,21 0,21.78225 0,22.75 L0,40.25 C0,41.21775 0.78225,42 1.75,42 L40.25,42 C41.21775,42 42,41.21775 42,40.25 L42,15.75 C42,14.78225 41.21775,14 40.25,14 Z M12.25,36.75 L7,36.75 L7,33.25 L12.25,33.25 L12.25,36.75 Z M12.25,29.75 L7,29.75 L7,26.25 L12.25,26.25 L12.25,29.75 Z M35,36.75 L29.75,36.75 L29.75,33.25 L35,33.25 L35,36.75 Z M35,29.75 L29.75,29.75 L29.75,26.25 L35,26.25 L35,29.75 Z M35,22.75 L29.75,22.75 L29.75,19.25 L35,19.25 L35,22.75 Z"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <span class="ml-1 duration-300 opacity-100 pointer-events-none ease-soft">Tournament</span>
                            </Link>
                        </li> */}


                        <li class="mt-0.5 w-full" onClick={() => { setMenu("Profile") }}>
                            <Link to="/profile" class={`${menu === 'Profile' && "rounded-lg shadow-soft-xl bg-white"} py-2.7 text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 transition-colors`} >
                                <div class={`${menu === 'Profile' && "bg-gradient-to-tl from-purple-700 to-pink-500"} shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5`}>
                                    <svg
                                        width={12}
                                        height={12}
                                        viewBox="0 0 40 44"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <title>document</title>
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g transform="translate(-1870.000000, -591.000000)" fill={"#FFFFFF"} fillRule="nonzero">
                                                <g transform="translate(1716.000000, 291.000000)">
                                                    <g transform="translate(154.000000, 300.000000)">
                                                        <path
                                                            className={`${menu !== 'Profile' && "fill-slate-800"} opacity-60`}
                                                            d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                                                        />
                                                        <path
                                                            className={`${menu !== 'Profile' && "fill-slate-800"}`}
                                                            d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"
                                                        />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <span class="ml-1 duration-300 opacity-100 pointer-events-none ease-soft">Profile</span>
                            </Link>
                        </li>


                    </ul>
                </div>

                <div class="mx-4">
                    {/* <!-- load phantom colors for card after: --> */}
                    <p class="invisible hidden text-gray-800 text-red-500 text-red-600 after:bg-gradient-to-tl after:from-gray-900 after:to-slate-800 after:bg-gradient-to-tl after:from-blue-600 after:to-cyan-400 after:bg-gradient-to-tl after:from-red-500 after:to-yellow-400 after:bg-gradient-to-tl after:from-green-600 after:to-lime-400 after:bg-gradient-to-tl after:from-red-600 after:to-rose-400 after:bg-gradient-to-tl after:from-slate-600 after:to-slate-300 text-lime-500 text-cyan-500 text-slate-400 text-fuchsia-500"></p>
                    <div class="after:opacity-65 after:bg-gradient-to-tl after:from-slate-600 after:to-slate-300 relative flex min-w-0 flex-col items-center break-words rounded-2xl border-0 border-solid border-blue-900 bg-white bg-clip-border shadow-none after:absolute after:top-0 after:bottom-0 after:left-0 after:z-10 after:block after:h-full after:w-full after:rounded-2xl after:content-['']" sidenav-card>
                        <div class="mb-7.5 absolute h-full w-full rounded-2xl bg-cover bg-center" ></div>
                        <div class="relative z-20 flex-auto w-full p-4 text-left text-white">
                            <div class="flex items-center justify-center w-8 h-8 mb-4 text-center bg-white bg-center rounded-lg icon shadow-soft-2xl">
                                <i class="top-0 z-10 text-transparent ni leading-none ni-diamond text-lg bg-gradient-to-tl from-slate-600 to-slate-300 bg-clip-text opacity-80" sidenav-card-icon></i>
                            </div>
                            <div class="transition-all duration-200 ease-nav-brand">
                                <h6 class="mb-0 text-white">Wanna a chance ?</h6>
                                <p class="mt-0 mb-4 font-semibold leading-tight text-xs">More to Learn. More to WIN</p>
                                <Link to="/tournament" class="inline-block w-full px-8 py-2 mb-0 font-bold text-center text-black uppercase transition-all ease-in bg-white border-0 border-white rounded-lg shadow-soft-md bg-150 leading-pro text-xs hover:shadow-soft-2xl hover:scale-102">Tournaments</Link>
                            </div>
                        </div>
                    </div>
                    {/* <!-- pro btn  --> */}
                    {localStorage.getItem('coreX') ? <div class="inline-block w-full px-6 py-3 my-4 font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg select-none shadow-soft-md bg-150 bg-x-25 leading-pro text-xs bg-gradient-to-tl from-purple-700 to-pink-500 hover:shadow-soft-2xl hover:scale-102" onClick={() => { userLogout() }}>Log Out</div> :
                        <Link class="inline-block w-full px-6 py-3 my-4 font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg select-none shadow-soft-md bg-150 bg-x-25 leading-pro text-xs bg-gradient-to-tl from-purple-700 to-pink-500 hover:shadow-soft-2xl hover:scale-102" to="/signin">Sign In</Link>}
                </div>
            </aside>
            <main class="ease-soft-in-out xl:ml-68.5 relative max-h-screen rounded-xl transition-all duration-200">
                {/* <!-- Navbar --> */}
                <nav class="relative flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all shadow-none duration-250 ease-soft-in rounded-2xl lg:flex-nowrap lg:justify-start" navbar-main navbar-scroll="true">
                    <div class="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
                        <nav className=" lg:w-1/3">
                            {/* <!-- breadcrumb --> */}
                            <ol class="flex flex-wrap pt-1 mr-12 bg-transparent rounded-lg sm:mr-16">
                                <li class="leading-normal text-sm">
                                    <Link class="opacity-50 text-slate-700" to="/" onClick={() => { setMenu("Dashboard") }}>Home</Link>
                                </li>
                                <li class="text-sm pl-2 capitalize leading-normal text-slate-700 before:float-left before:pr-2 before:text-gray-600 before:content-['/']" aria-current="page">{menu}</li>
                            </ol>
                            <h6 class="mb-0 font-bold capitalize">{menu}</h6>
                        </nav>
                        <div className="hidden lg:block overflow-hidden w-full">
                            <div className="flex animate-marquee w-full" ref={marqueeRef}
                            >
                                {props.indices && props.indices.map((stock, index) => (
                                   <> {stock && stock.price && stock.change && <div
                                        key={index}
                                        className="mr-8 flex items-center space-x-2 text-lg font-medium"
                                    >
                                        <span className="text-nowrap font-bold">{stock.symbol}</span>
                                        <span>{stock && stock.price && stock.price.toFixed(2)}</span>
                                        <span
                                            className={`${stock.change > 0 ? 'text-green-500' : 'text-red-500'
                                                }`}
                                        >
                                            {stock.change > 0 ? `+${stock.change.toFixed(2)}` : stock.change.toFixed(2)}
                                        </span>
                                    </div> }</>
                                ))}
                                {props.indices && props.indices.map((stock, index) => (
                                   <> {stock && stock.price && stock.change && <div
                                        key={index}
                                        className="mr-8 flex items-center space-x-2 text-lg font-medium"
                                    >
                                        <span className="text-nowrap font-bold">{stock.symbol}</span>
                                        <span>{stock && stock.price && stock.price.toFixed(2)}</span>
                                        <span
                                            className={`${stock.change > 0 ? 'text-green-500' : 'text-red-500'
                                                }`}
                                        >
                                            {stock.change > 0 ? `+${stock.change.toFixed(2)}` : stock.change.toFixed(2)}
                                        </span>
                                    </div> }</>
                                ))}
                            </div>
                        </div>
                        <div class=" lg:w-1/5 flex items-center mt-2 grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto">
                            <ul class="flex flex-row justify-end pl-0 mb-0 list-none md-max:w-full m-auto">
                                
                                {!localStorage.getItem('coreX') && <li class="flex items-center">
                                    <Link to="/signin" class="block px-0 py-2 font-semibold transition-all ease-nav-brand text-sm text-slate-500">
                                        <i class="fa fa-user sm:mr-1"></i>
                                        <span class="hidden sm:inline">Sign In</span>
                                    </Link>
                                </li>}
                                <li class="flex items-center pl-4 hidden">
                                    <a href="javascript:;" class="block p-0 transition-all ease-nav-brand text-sm text-slate-500" ref={sidenavTrigger}>
                                        <div class="w-4.5 overflow-hidden">
                                            <i class="ease-soft mb-0.75 relative block h-0.5 rounded-sm bg-slate-500 transition-all"></i>
                                            <i class="ease-soft mb-0.75 relative block h-0.5 rounded-sm bg-slate-500 transition-all"></i>
                                            <i class="ease-soft relative block h-0.5 rounded-sm bg-slate-500 transition-all"></i>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className=" lg:hidden overflow-hidden w-full">
                            <div className="flex animate-marquee w-full">
                            {props.indices && props.indices.map((stock, index) => (
                                   <> {stock && stock.price && stock.change && <div
                                        key={index}
                                        className="mr-8 flex items-center space-x-2 text-lg font-medium"
                                    >
                                        <span className="text-nowrap font-bold">{stock.symbol}</span>
                                        <span>{stock && stock.price && stock.price.toFixed(2)}</span>
                                        <span
                                            className={`${stock.change > 0 ? 'text-green-500' : 'text-red-500'
                                                }`}
                                        >
                                            {stock.change > 0 ? `+${stock.change.toFixed(2)}` : stock.change.toFixed(2)}
                                        </span>
                                    </div> }</>
                                ))}
                                {props.indices && props.indices.map((stock, index) => (
                                   <> {stock && stock.price && stock.change && <div
                                        key={index}
                                        className="mr-8 flex items-center space-x-2 text-lg font-medium"
                                    >
                                        <span className="text-nowrap font-bold">{stock.symbol}</span>
                                        <span>{stock && stock.price && stock.price.toFixed(2)}</span>
                                        <span
                                            className={`${stock.change > 0 ? 'text-green-500' : 'text-red-500'
                                                }`}
                                        >
                                            {stock.change > 0 ? `+${stock.change.toFixed(2)}` : stock.change.toFixed(2)}
                                        </span>
                                    </div> }</>
                                ))}
                            </div>
                        </div>
                    </div>
                </nav>


            </main>
            <main className="ease-soft-in-out xl:ml-68.5 relative max-h-screen rounded-xl transition-all duration-200">
                <Outlet />
            </main>
            <div className="xl:hidden fixed bottom-0 left-0 z-50 p-1 pt-2 flex justify-center space-x-5 w-full break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border">
                <ul class="flex flex-row pl-0 mb-0">
                    <li class="mt-0.5 w-full" onClick={() => { setMenu("Dashboard") }}>
                        <Link to="/dashboard" class={`py-2.7  text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap ${menu === 'Dashboard' && "rounded-lg shadow-soft-xl bg-white"} px-4 font-semibold text-slate-700 transition-colors`} >
                            <div class={`${menu === 'Dashboard' && "bg-gradient-to-tl from-purple-700 to-pink-500"} shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5`}>
                                <svg width="12px" height="12px" viewBox="0 0 45 40" version="1.1">
                                    <title>shop</title>
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-1716.000000, -439.000000)" fill="#FFFFFF" fillRule="nonzero">
                                            <g transform="translate(1716.000000, 291.000000)">
                                                <g transform="translate(0.000000, 148.000000)">
                                                    <path
                                                        className={`${menu !== 'Dashboard' && "fill-slate-800"} opacity-60`}
                                                        d="M46.7199583,10.7414583 L40.8449583,0.949791667 C40.4909749,0.360605034 39.8540131,0 39.1666667,0 L7.83333333,0 C7.1459869,0 6.50902508,0.360605034 6.15504167,0.949791667 L0.280041667,10.7414583 C0.0969176761,11.0460037 -1.23209662e-05,11.3946378 -1.23209662e-05,11.75 C-0.00758042603,16.0663731 3.48367543,19.5725301 7.80004167,19.5833333 L7.81570833,19.5833333 C9.75003686,19.5882688 11.6168794,18.8726691 13.0522917,17.5760417 C16.0171492,20.2556967 20.5292675,20.2556967 23.494125,17.5760417 C26.4604562,20.2616016 30.9794188,20.2616016 33.94575,17.5760417 C36.2421905,19.6477597 39.5441143,20.1708521 42.3684437,18.9103691 C45.1927731,17.649886 47.0084685,14.8428276 47.0000295,11.75 C47.0000295,11.3946378 46.9030823,11.0460037 46.7199583,10.7414583 Z"
                                                    ></path>
                                                    <path className={`${menu !== 'Dashboard' && "fill-slate-800"}`}
                                                        d="M39.198,22.4912623 C37.3776246,22.4928106 35.5817531,22.0149171 33.951625,21.0951667 L33.92225,21.1107282 C31.1430221,22.6838032 27.9255001,22.9318916 24.9844167,21.7998837 C24.4750389,21.605469 23.9777983,21.3722567 23.4960833,21.1018359 L23.4745417,21.1129513 C20.6961809,22.6871153 17.4786145,22.9344611 14.5386667,21.7998837 C14.029926,21.6054643 13.533337,21.3722507 13.0522917,21.1018359 C11.4250962,22.0190609 9.63246555,22.4947009 7.81570833,22.4912623 C7.16510551,22.4842162 6.51607673,22.4173045 5.875,22.2911849 L5.875,44.7220845 C5.875,45.9498589 6.7517757,46.9451667 7.83333333,46.9451667 L19.5833333,46.9451667 L19.5833333,33.6066734 L27.4166667,33.6066734 L27.4166667,46.9451667 L39.1666667,46.9451667 C40.2482243,46.9451667 41.125,45.9498589 41.125,44.7220845 L41.125,22.2822926 C40.4887822,22.4116582 39.8442868,22.4815492 39.198,22.4912623 Z"
                                                    ></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>

                        </Link>
                    </li>

                    {/* <li class="mt-0.5 w-full" onClick={() => { setMenu("Tournament") }}>
                        <Link to="/tournament" class={`py-2.7 text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 transition-colors ${menu === 'Tournament' && "rounded-lg shadow-soft-xl bg-white"} `}>
                            <div class={`${menu === 'Tournament' && "bg-gradient-to-tl from-purple-700 to-pink-500"} shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5`}>
                                <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 42 42"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <title>office</title>
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-1869.000000, -293.000000)" fill="#FFFFFF" fillRule="nonzero">
                                            <g transform="translate(1716.000000, 291.000000)">
                                                <g transform="translate(153.000000, 2.000000)">
                                                    <path
                                                        className={`${menu !== 'Tournament' && "fill-slate-800"} opacity-60`}
                                                        d="M12.25,17.5 L8.75,17.5 L8.75,1.75 C8.75,0.78225 9.53225,0 10.5,0 L31.5,0 C32.46775,0 33.25,0.78225 33.25,1.75 L33.25,12.25 L29.75,12.25 L29.75,3.5 L12.25,3.5 L12.25,17.5 Z"
                                                    />
                                                    <path
                                                        className={`${menu !== 'Tournament' && "fill-slate-800"}`}
                                                        d="M40.25,14 L24.5,14 C23.53225,14 22.75,14.78225 22.75,15.75 L22.75,38.5 L19.25,38.5 L19.25,22.75 C19.25,21.78225 18.46775,21 17.5,21 L1.75,21 C0.78225,21 0,21.78225 0,22.75 L0,40.25 C0,41.21775 0.78225,42 1.75,42 L40.25,42 C41.21775,42 42,41.21775 42,40.25 L42,15.75 C42,14.78225 41.21775,14 40.25,14 Z M12.25,36.75 L7,36.75 L7,33.25 L12.25,33.25 L12.25,36.75 Z M12.25,29.75 L7,29.75 L7,26.25 L12.25,26.25 L12.25,29.75 Z M35,36.75 L29.75,36.75 L29.75,33.25 L35,33.25 L35,36.75 Z M35,29.75 L29.75,29.75 L29.75,26.25 L35,26.25 L35,29.75 Z M35,22.75 L29.75,22.75 L29.75,19.25 L35,19.25 L35,22.75 Z"
                                                    />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>

                        </Link>
                    </li> */}


                   {props.logIn &&  <li class="mt-0.5 w-full" onClick={() => { setMenu("Profile") }}>
                        <Link to="/profile" class={`${menu === 'Profile' && "rounded-lg shadow-soft-xl bg-white"} py-2.7 text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 transition-colors`} >
                            <div class={`${menu === 'Profile' && "bg-gradient-to-tl from-purple-700 to-pink-500"} shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5`}>
                                <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 40 44"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <title>document</title>
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-1870.000000, -591.000000)" fill={"#FFFFFF"} fillRule="nonzero">
                                            <g transform="translate(1716.000000, 291.000000)">
                                                <g transform="translate(154.000000, 300.000000)">
                                                    <path
                                                        className={`${menu !== 'Profile' && "fill-slate-800"} opacity-60`}
                                                        d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                                                    />
                                                    <path
                                                        className={`${menu !== 'Profile' && "fill-slate-800"}`}
                                                        d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"
                                                    />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>

                        </Link>
                    </li>}


                </ul>
            </div>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        indices: state.layout.indices,
        logIn: state.auth.logIn
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getIndicesDetails: param => dispatch(getIndicesDetails())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);