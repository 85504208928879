import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { loadUserConfig } from "../actions/user";
import { baseUrl, getStockNameForSymbol } from "../constants";
import { userAccountLogout } from "../actions/auth";
import { useNavigate } from "react-router-dom";

const Profile = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(loadUserConfig(localStorage.getItem('coreX')));
    }, []);

    const userLogout = () => {
        dispatch(userAccountLogout());
        navigate("/");
    }


    return (
        <div>
            <div class="w-full pl-6 pr-6 pt-1 mx-auto">
                <div class="relative flex items-center p-0 mt-6 overflow-hidden bg-center bg-cover min-h-40 rounded-2xl" style={{ backgroundImage: "url('../assets/img/curved-images/curved0.jpg')", "backgroundPositionY": "50%" }}>
                    <span class="absolute inset-y-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-purple-700 to-pink-500 opacity-60"></span>
                </div>
                <div class="relative flex flex-col flex-auto min-w-0 p-4 mx-6 -mt-16 overflow-hidden break-words border-0 shadow-blur rounded-2xl bg-white/80 bg-clip-border backdrop-blur-2xl backdrop-saturate-200">
                    <div class="flex -mx-3">
                        <div class="flex-none w-auto max-w-full px-3">
                            <div class="text-base ease-soft-in-out h-18.5 w-18.5 relative inline-flex items-center justify-center rounded-xl text-white transition-all duration-200">
                                <img src={`${props.user.user && props.user.user.data.gender && props.user.user.data.gender === "male" ? "../assets/img/male.jpg" : "../assets/img/male.jpg"}`} alt="profile_image" class="w-full shadow-soft-sm rounded-xl" />
                            </div>
                        </div>
                        <div class="flex-none w-auto max-w-full px-3 my-auto">
                            <div class="h-full">
                                <h5 class="mb-1">{props.user.user && props.user.user.data.firstName} {props.user.user && props.user.user.data.lastName}</h5>
                                <p class="mb-0 font-semibold leading-normal text-sm">Beginner | TradeStox</p>
                            </div>
                        </div>
                        <div class="w-full max-w-full px-3 mx-auto mt-4 sm:my-auto sm:mr-0 md:w-1/2 md:flex-none lg:w-4/12">
                            <div class="relative right-0">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full pl-6 pr-6 pt-1  mx-auto">
                <div class="flex flex-wrap -mx-3">
                    <div class="w-full max-w-full px-3 lg-max:mt-6 xl:w-4/12">
                        <div class="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                            <div class="p-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl">
                                <div class="flex flex-wrap -mx-3">
                                    <div class="flex items-center w-full max-w-full px-3 shrink-0 md:w-8/12 md:flex-none">
                                        <h6 class="mb-0">Profile Information</h6>
                                    </div>
                                    <div class="w-full max-w-full px-3 text-right shrink-0 md:w-4/12 md:flex-none">
                                        <a href="javascript:;" data-target="tooltip_trigger" data-placement="top">
                                            <i class="leading-normal fas fa-user-edit text-sm text-slate-400"></i>
                                        </a>
                                        {/* <div data-target="tooltip" class="hidden px-2 py-1 text-center text-white bg-black rounded-lg text-sm" role="tooltip">
                                            Edit Profile
                                            <div class="invisible absolute h-2 w-2 bg-inherit before:visible before:absolute before:h-2 before:w-2 before:rotate-45 before:bg-inherit before:content-['']" data-popper-arrow></div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div class="flex-auto p-4 ">
                                <p class="leading-normal text-sm">Hi, I’m {props.user.user && props.user.user.data.firstName} {props.user.user && props.user.user.data.lastName},Driven by a passion for market exploration and a desire to grow in the trading space, I am always seeking ways to improve my skills and stay updated with industry trends.</p>
                                <hr class="h-px my-6 bg-transparent bg-gradient-to-r from-transparent via-white to-transparent" />
                                <ul class="flex flex-col pl-0 mb-0  items-center">
                                    <li class="relative block px-4 py-2 pt-0 pl-0 leading-normal bg-white border-0 rounded-t-lg text-sm text-inherit"><strong class="text-slate-700">Full Name:</strong> &nbsp; {props.user.user && props.user.user.data.firstName} {props.user.user && props.user.user.data.lastName}</li>
                                    <li class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-sm text-inherit"><strong class="text-slate-700">Mobile:</strong> &nbsp; {props.user.user && props.user.user.data.phone}</li>
                                    <li class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-sm text-inherit"><strong class="text-slate-700">Email:</strong> &nbsp; {props.user.user && props.user.user.email}</li>
                                    <li class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-sm text-inherit"><strong class="text-slate-700">Location:</strong> &nbsp; {props.user.user && props.user.user.data.city}</li>
                                    {/* <li class="relative block px-4 py-2 pb-0 pl-0 bg-white border-0 border-t-0 rounded-b-lg text-inherit">
                                        <strong class="leading-normal text-sm text-slate-700">Social:</strong> &nbsp;
                                        <a class="inline-block py-0 pl-1 pr-2 mb-0 font-bold text-center text-blue-800 align-middle transition-all bg-transparent border-0 rounded-lg shadow-none cursor-pointer leading-pro text-xs ease-soft-in bg-none" href="javascript:;">
                                            <i class="fab fa-facebook fa-lg"></i>
                                        </a>
                                        <a class="inline-block py-0 pl-1 pr-2 mb-0 font-bold text-center align-middle transition-all bg-transparent border-0 rounded-lg shadow-none cursor-pointer leading-pro text-xs ease-soft-in bg-none text-sky-600" href="javascript:;">
                                            <i class="fab fa-twitter fa-lg"></i>
                                        </a>
                                        <a class="inline-block py-0 pl-1 pr-2 mb-0 font-bold text-center align-middle transition-all bg-transparent border-0 rounded-lg shadow-none cursor-pointer leading-pro text-xs ease-soft-in bg-none text-sky-900" href="javascript:;">
                                            <i class="fab fa-instagram fa-lg"></i>
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="w-full max-w-full px-3 lg-max:mt-6 lg:w-8/12 mt-2 lg:mt-0">
                        <div class="flex flex-wrap -mx-3">
                            <div class="flex-none w-full max-w-full px-3">
                                <div class="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                                    <div class="p-6 pb-0 mb-0 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                                        <h6>Stock List</h6>
                                    </div>
                                    <div class="flex-auto px-0 pt-0 pb-2">
                                        <div class="p-0 overflow-x-auto">
                                            {props.user.plData && props.user.plData.holdingsData && <table class="items-center justify-center w-full mb-0 align-top border-gray-200 text-slate-500">
                                                <thead class="align-bottom">
                                                    <tr>
                                                        <th class="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Stock</th>
                                                        <th class="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Quantity</th>
                                                        <th class="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Total Price</th>
                                                        <th class="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Invested</th>
                                                        <th class="px-6 py-3 pl-2 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">P/L</th>
                                                        <th class="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {props.user.plData.holdingsData.map((item) =>
                                                        <>{item.quantity > 0 &&
                                                        <tr>
                                                            <td class="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                                <div class="flex px-2">
                                                                    <div>
                                                                        <img src={`${baseUrl}//assets/img/small-logos/logo-spotify.svg`} class="inline-flex items-center justify-center mr-2 text-sm text-white transition-all duration-200 rounded-full ease-soft-in-out h-9 w-9" alt="spotify" />
                                                                    </div>
                                                                    <div class="my-auto">
                                                                        <h6 class="mb-0 text-sm leading-normal">{getStockNameForSymbol(item.id)}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                                <p class="mb-0 text-sm font-semibold leading-normal">{item.quantity}</p>
                                                            </td>
                                                            <td class="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                                <span class="text-xs font-semibold leading-tight">{item.current_amount}</span>
                                                            </td>
                                                            <td class="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                                <span class="text-xs font-semibold leading-tight">{item.total_amount}</span>
                                                            </td>
                                                            <td class="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                                <div class="flex items-center justify-center">
                                                                    <span class="mr-2 text-xs font-semibold leading-tight">{item.change.toFixed(2)}</span>
                                                                    {/* <div>
                                                                        <div class="text-xs h-0.75 w-30 m-0 flex overflow-visible rounded-lg bg-gray-200">
                                                                            <div class="duration-600 ease-soft bg-gradient-to-tl from-blue-600 to-cyan-400 -mt-0.38 -ml-px flex h-1.5 w-3/5 flex-col justify-center overflow-hidden whitespace-nowrap rounded bg-fuchsia-500 text-center text-white transition-all" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </td>
                                                            <td class="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                                <button class="inline-block px-6 py-3 mb-0 text-xs font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg shadow-none leading-pro ease-soft-in bg-150 tracking-tight-soft bg-x-25 text-slate-400">
                                                                    <i class="text-xs leading-tight fa fa-ellipsis-v"></i>
                                                                </button>
                                                            </td>
                                                        </tr>}</>
                                                    )}

                                                </tbody>
                                            </table>}

                                            {!props.user.plData || !props.user.plData.holdingsData || props.user.plData.holdingsData.length === 0 &&
                                                <div className="flex w-full items-center justify-center">
                                                    <h6>Build your portfolio!!! Build your empire!!!</h6>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full max-w-full px-3 lg-max:mt-6 lg:w-8/12 xl:hidden">
                        {localStorage.getItem('coreX') && <div class="inline-block w-full px-6 py-3 my-4 font-bold text-center text-white uppercase align-middle transition-all ease-in border-0 rounded-lg select-none shadow-soft-md bg-150 bg-x-25 leading-pro text-xs bg-gradient-to-tl from-purple-700 to-pink-500 hover:shadow-soft-2xl hover:scale-102" onClick={() => { userLogout() }}>Log Out</div>}
                    </div>
                </div>

            </div>
            <div class="w-full p-6 mx-auto">

            </div>
        </div>
    )
}

const mapStateToProps = state => {
    console.log(state);
    return {
        user: state.user.user || {}
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadUserConfig: param => dispatch(loadUserConfig(param))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);