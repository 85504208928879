const initailState = {
  isLoginVisible: true,
  is2FAVisible: false,
  isRegisterVisible: false,
  logIn: false
};

const authDeatils = (state = initailState, action) => {
  console.log("action----->>>>>>>>>", action.type);
  switch (action.type) {
    case "TOGGLE_LOGIN_VISIBILITY":
      return {
        ...state,
        isLoginVisible: action.isLoginVisible,
      };
    case "TOGGLE_2FA_VISIBILITY":
      return {
        ...state,
        is2FAVisible: action.is2FAVisible,
      };
    case "TOGGLE_REGISTER_VISIBILITY":
      return {
        ...state,
        isRegisterVisible: action.isRegisterVisible,
      };
    case "USER_LOGIN_SUCCESS":
      return {
        ...state,
        accessToken: action.accessToken,
        logIn: action.logIn,
        status: "success"
      };
    case "USER_REGISTER_SUCCESS":
      return {
        ...state,
        accessToken: action.accessToken,
        logIn: action.logIn,
        status: "success"
      };
    case "USER_LOGIN_FAILED":
      return {
        ...state,
        logIn: action.logIn,
        status: "failed",
        error: action.error
      };
    case "USER_REGISTER_FAILED":
      return {
        ...state,
        logIn: action.logIn,
        status: "failed",
        error: action.error
      };
    case "USER_LOGOUT_SUCCESS":
      return {
        ...state,
        logIn: action.logIn,
        status: "failed"
      };
    case "USER_FOUND_SUCCESS":
      return {
        ...state,
        logIn: true
      };
    case "USER_FOUND_FAILURE":
      return {
        ...state,
        logIn: false
      };
    default:
      return state;
  }
};

export default authDeatils;