import logo from './logo.svg';
import './App.css';
import { BsArrowLeftShort } from "react-icons/bs";
import { BsBarChartLine } from "react-icons/bs";
import { useEffect, useState } from 'react';
import { MdSpaceDashboard } from "react-icons/md";
import Sidebar from './new/Sidebar';
import Main from './new/Main';
import { applyMiddleware, createStore } from 'redux';
import Layout from './new/Layout';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from './new/Dashboard';
import Tournaments from './new/Tournaments';
import Profile from './new/Profile';
import SignIn from './new/SignIn';
import Signup from './new/Signup';
import { thunk } from 'redux-thunk';
import corex from './reducers';
import { Provider } from 'react-redux';


const styles = require.context('../src/assets/css', false, /\.css$/);
styles.keys().forEach(styles);
const fonts = require.context('../src/assets/fonts', false, /\.(eot|svg|ttf|woff|woff2)$/);
fonts.keys().forEach(fonts);
const img1 = require.context('../src/assets/img', false, /\.(jpg|svg|png)$/);
img1.keys().forEach(img1);





function App() {
  const [open, setOpen] = useState(true);
  const store = createStore(corex, applyMiddleware(thunk));

  return (
    <Provider store={store}>
      <div class="h-screen m-0 font-sans antialiased font-normal text-base leading-default bg-gray-50 text-slate-500">
        <BrowserRouter>
          <Routes>
            {/* Only wrap with Layout for non-signin routes */}
            <Route element={<Layout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/tournament" element={<Tournaments />} />
              <Route path="/profile" element={<Profile />} />
            </Route>
            {/* Direct route for /signin */}
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<Signup />} />
          </Routes>
        </BrowserRouter>
      </div>
    </Provider>
  );
}

const MainWrapper = ({ children }) => (
  <main className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200">
    {children}
  </main>
);

export default App;
