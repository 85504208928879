import { connect, useDispatch, useSelector } from "react-redux";
import { authVisibilty, userAccountLogin } from "../actions/auth";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const SignIn = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const validateAndLogin = (event) => {
        console.log(email, password)
        dispatch(userAccountLogin({ email: email, password: password }));
    }

    useEffect(() => {
        if (props.auth.error) {
            setShowError(true);
        } else {
            setShowError(false)
        }
    }, [props.auth.error])

    useEffect(() => {
        if (props.auth.status === 'success') {
            navigate('/')
        }
    }, [props.auth.status])

    return (
        <div class="h-full">
            <div class="container sticky top-0 z-sticky">
                <div class="flex flex-wrap -mx-3">
                    <div class="w-full max-w-full px-3 flex-0">
                        {/* <!-- Navbar --> */}
                        <nav class="absolute top-0 left-0 right-0 z-30 flex flex-wrap items-center px-4 py-2 mx-6 my-4 shadow-soft-2xl rounded-blur bg-white/80 backdrop-blur-2xl backdrop-saturate-200 lg:flex-nowrap lg:justify-start">
                            <div class="flex items-center justify-between w-full p-0 pl-6 mx-auto flex-wrap-inherit">
                                <Link class="py-2.375 text-sm mr-4 ml-4 whitespace-nowrap font-bold text-slate-700 lg:ml-0" to="/dashboard">  Go To Market Dashboard </Link>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <main class="h-full mt-0 transition-all duration-200 ease-soft-in-out">
                <section className="h-full">
                    <div class="h-full relative flex items-center p-0 overflow-hidden bg-center bg-cover min-h-75-screen">
                        <div class="h-full container z-10">
                            <div class="flex flex-wrap mt-0 -mx-3">
                                <div class="flex flex-col w-full max-w-full px-3 mx-auto md:flex-0 shrink-0 md:w-6/12 lg:w-5/12 xl:w-4/12">
                                    <div class="relative flex flex-col min-w-0 mt-32 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                                        <div class="p-6 pb-0 mb-0 bg-transparent border-b-0 rounded-t-2xl">
                                            <h3 class="relative z-10 font-bold text-transparent bg-gradient-to-tl from-blue-600 to-cyan-400 bg-clip-text">Welcome back</h3>
                                            <p class="mb-0">Enter your email and password to sign in</p>
                                        </div>
                                        <div class="flex-auto p-6">
                                            <form role="form">
                                                <label class="mb-2 ml-1 font-bold text-xs text-slate-700">Email</label>
                                                <div class="mb-4">
                                                    <input type="email" class="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:outline-none focus:transition-shadow" placeholder="Email" aria-label="Email" aria-describedby="email-addon" onChange={(e) => { setEmail(e.target.value) }} />
                                                </div>
                                                <label class="mb-2 ml-1 font-bold text-xs text-slate-700">Password</label>
                                                <div class="mb-4">
                                                    <input type="password" class="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:outline-none focus:transition-shadow" placeholder="Password" aria-label="Password" aria-describedby="password-addon" onChange={(e) => { setPassword(e.target.value) }} />
                                                </div>
                                                <div class="min-h-6 mb-0.5 block pl-12">
                                                    <input id="rememberMe" class="mt-0.54 rounded-10 duration-250 ease-soft-in-out after:rounded-circle after:shadow-soft-2xl after:duration-250 checked:after:translate-x-5.25 h-5 relative float-left -ml-12 w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-slate-800/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-slate-800/95 checked:bg-slate-800/95 checked:bg-none checked:bg-right" type="checkbox" checked="" />
                                                    <label class="mb-2 ml-1 font-normal cursor-pointer select-none text-sm text-slate-700" for="rememberMe">Remember me</label>
                                                </div>
                                                <div class="text-center">
                                                    <button type="button" class="inline-block w-full px-6 py-3 mt-6 mb-0 font-bold text-center text-white uppercase align-middle transition-all bg-transparent border-0 rounded-lg cursor-pointer shadow-soft-md bg-x-25 bg-150 leading-pro text-xs ease-soft-in tracking-tight-soft bg-gradient-to-tl from-blue-600 to-cyan-400 hover:scale-102 hover:shadow-soft-xs active:opacity-85" onClick={()=>{validateAndLogin()}}>Sign in</button>
                                                </div>
                                                {showError ? <span class='text-xs md:text-sm text-red-600 text-center justify-center mb-1 flex'>{props.auth.error}</span> : ""}
                                            </form>
                                        </div>
                                        <div class="p-6 px-1 pt-0 text-center bg-transparent border-t-0 border-t-solid rounded-b-2xl lg:px-2">
                                            <p class="mx-auto mb-6 leading-normal text-sm">
                                                Don't have an account?
                                                <Link to="/signup" class="relative z-10 font-semibold text-transparent bg-gradient-to-tl from-blue-600 to-cyan-400 bg-clip-text">Sign up</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full max-w-full px-3 lg:flex-0 shrink-0 md:w-6/12">
                                    <div class="absolute top-0 hidden w-3/5 h-full -mr-32 overflow-hidden -skew-x-10 -right-40 rounded-bl-xl md:block">
                                        <div class="absolute inset-x-0 top-0 z-0 h-full -ml-16 bg-cover skew-x-10" style={{ backgroundImage: "url('../assets/img/curved-images/curved6.jpg')" }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

const mapStateToProps = state => {
    console.log("state-login->>", state);
    return {
        auth: state.auth || {}
    }
}

const mapDispatchToProps = dispatch => {
    return {
        authVisibilty: param => dispatch(authVisibilty(param))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);